/**
 * Parses a string of filter parameters in the format "key1=value1,key2=value2"
 * into a Record<string, string> object.
 *
 * @param filterParamsString - String in format "param=value(,param=value)*"
 * @returns Parsed parameters as a Record<string, string> or undefined if input is empty
 */
export const parseFilterParams = (
  filterParamsString?: string,
): Record<string, string> | undefined => {
  if (!filterParamsString) {
    return undefined;
  }

  return filterParamsString.split(',').reduce((acc, pair) => {
    const [key, value] = pair.split('=');
    if (key && value) {
      acc[key.trim()] = value.trim();
    }
    return acc;
  }, {} as Record<string, string>);
};

/**
 * Adds custom filter parameters to a URLSearchParams object
 *
 * @param searchParams - The URLSearchParams object to add parameters to
 * @param customFilterParams - The custom filter parameters to add
 * @returns The updated URLSearchParams object
 */
export const addCustomFilterParams = (
  searchParams: URLSearchParams,
  customFilterParams?: Record<string, string>,
): URLSearchParams => {
  if (!customFilterParams) {
    return searchParams;
  }

  Object.entries(customFilterParams).forEach(([key, value]) => {
    searchParams.append(key, value);
  });

  return searchParams;
};
