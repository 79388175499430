'use client';

import { memo } from 'react';

import Link from 'next/link';

import { Typography } from '@gds/Typography/Typography';

import { KenticoImage } from '@shared-components/KenticoImage/KenticoImage';

import { VideoItem } from './Entities/MultiVid.entity';
import { PlayButton } from './PlayButton';

import styles from './MultiVid.module.css';

type VideoCardProps = {
  item: VideoItem;
  index: number;
  categoryIndex: number;
};

export const VideoCard = memo(({ item, index }: VideoCardProps) => (
  <Link
    href={item.videoId ? `https://www.youtube.com/watch?v=${item.videoId}` : item.url || '#'}
    target="_blank"
    className={styles.videoItem}
    aria-label={item.title}
    data-test-id={`multi-vid-item-${index}`}
    // @ts-expect-error - css custom property
    style={{ '--index': index } as React.CSSProperties}
  >
    <div className={styles.imageContainer}>
      <KenticoImage
        src={item.image[0]}
        alt={item.title}
        fill
        sizes="(max-width: 768px) 100vw, 33vw"
        style={{ objectFit: 'cover' }}
        loading="lazy"
      />
      <PlayButton />
    </div>
    <Typography variant="body1" className={styles.videoTitle}>
      {item.title}
    </Typography>
  </Link>
));

VideoCard.displayName = 'VideoCard';
