'use client';

import { useAtomValue } from 'jotai';
import Link from 'next/link';

import { searchParamsAtom } from '@core/Atoms/SearchParams/SearchParams.atom';
import { userIdAtom } from '@core/Atoms/User/UserId.atom';
import Button from '@gds/Button/Button';
import { generateCtaClickedTracking } from '@tracking/CtaClicked.tracking';
import { trackCustomEvent } from '@tracking/Utils/TrackCustomEvent';

import styles from '../../FilteredVehicleCarousel.module.css';

type FVCCta = {
  label: string;
  href: string;
  trackingLoc: string;
};

export const Cta = ({ label, href, trackingLoc }: FVCCta) => {
  const userId = useAtomValue(userIdAtom);
  const searchParams = useAtomValue(searchParamsAtom);

  const trackClick = () => {
    const { event, context } = generateCtaClickedTracking({
      searchParams,
      userId,
      // @ts-expect-error //formState is not relevant here
      formState: {},
      ctaLocation: trackingLoc,
      ctaType: 'link',
      ctaWording: label,
      ctaUrl: href,
    });

    trackCustomEvent({ event, context });
  };

  return (
    <Link href={href} passHref legacyBehavior prefetch={false}>
      <Button
        className={styles.cta}
        Component="a"
        size="large"
        variant="link"
        data-test-id="ctaButton"
        onClick={trackClick}
      >
        {label}
      </Button>
    </Link>
  );
};
