'use client';

import React from 'react';

import Cookies from 'js-cookie';

import { Locale } from '../../Entities/Locale/Locale.entity';

type ErrorFallbackProps = {
  fallback?: React.ReactNode | ((error: Error) => React.ReactNode);
  children?: React.ReactNode;
};

type ErrorFallbackState = {
  hasError: boolean;
  error: Error | null;
};

const messages = {
  uk: 'Something went wrong.',
  fr: "Quelque chose s'est mal passé.",
  de: 'Etwas ist schief gelaufen.',
};

export class ErrorFallback extends React.Component<ErrorFallbackProps, ErrorFallbackState> {
  constructor(props: ErrorFallbackProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error): ErrorFallbackState {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error('Error caught:', error, errorInfo);
  }

  render(): React.ReactNode {
    const { hasError, error } = this.state;
    const { fallback, children } = this.props;

    const locale = Cookies.get('locale') as Locale;

    if (hasError && error) {
      if (typeof fallback === 'function') {
        return fallback(error);
      }

      return fallback || <h1>{messages[locale]}</h1>;
    }

    return children;
  }
}
