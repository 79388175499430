import { Locale } from '../../Entities/Locale/Locale.entity';

import { mappedLocales } from './MappedLocales';

export const formatNumber = (
  value: number,
  locale: Locale = 'uk',
  minimumFractionDigits = 0,
  maximumFractionDigits = 0,
  config: Intl.NumberFormatOptions = {},
) => {
  const options: Intl.NumberFormatOptions = {
    minimumFractionDigits,
    maximumFractionDigits,
    ...config,
  };

  return new Intl.NumberFormat(mappedLocales[locale], options).format(value);
};
