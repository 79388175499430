import { LeadFormState } from '@core/Atoms/Pdp/FormState.atom';
import { PostcodeAtomType } from '@core/Atoms/Postcode/Postcode.atom';
import { SearchParamsType } from '@core/Atoms/SearchParams/SearchParams.atom';
import { isWindowDefined } from '@core/Constants/Window';
import { Locale } from '@core/Entities/Locale/Locale.entity';
import { LocationState } from '@core/Entities/Search/LocationState.entity';
import { getStrippedHtml } from '@core/Utils/Html/GetStrippedHtml';
import { trackCustomEvent } from '@tracking/Utils/TrackCustomEvent';

import { generateCampaignPromoTrackingData } from '../Tracking/CampaignPromo.tracking';

export const enrichPlpPath = (
  urlPath: string,
  pcAtom: [PostcodeAtomType, (payload: PostcodeAtomType) => void],
  location: LocationState,
  locale: Locale,
) => {
  const pathNoStartSlash = urlPath.startsWith('/') ? urlPath.slice(1) : urlPath;
  const pathWithLocale = pathNoStartSlash.startsWith(`${locale}`)
    ? pathNoStartSlash
    : `${locale}/${pathNoStartSlash}`;
  const pathParts = pathWithLocale.split('?');
  const searchParams = new URLSearchParams(pathParts[1]);

  const [postcode, setPostcode] = pcAtom;
  const storedPC = postcode[locale];
  const hasLatLon =
    location?.location && !(location.location.lat === 0 && location.location.lon === 0);

  //PC query with an empty val mean we need to clear stored PC
  if (searchParams.get('postcode') === '') {
    setPostcode({ [locale]: '' });
    return `/${pathWithLocale}`;
  }

  if (storedPC && !searchParams.has('postcode')) {
    searchParams.append('postcode', storedPC);
  }
  if (hasLatLon && !searchParams.has('lat') && !searchParams.has('lon')) {
    searchParams.append('lat', location.location.lat.toString());
    searchParams.append('lon', location.location.lon.toString());
  }

  return `/${pathParts[0]}?${searchParams.toString()}`;
};

export const trackClick = (
  promoData: {
    type: 'block' | 'banner';
    pathWithLocale: string;
    text: string;
    location?: string;
  },
  sessionData: {
    searchParams: SearchParamsType;
    userId: string | null | undefined;
    formState: LeadFormState;
  },
) => {
  const pathWStartSlash = promoData.pathWithLocale.startsWith('/')
    ? promoData.pathWithLocale
    : `/${promoData.pathWithLocale}`;
  const trackingUrl = isWindowDefined ? window.location.origin + pathWStartSlash : '';
  const { event, context } = generateCampaignPromoTrackingData({
    searchParams: sessionData.searchParams,
    userId: sessionData.userId,
    formState: sessionData.formState,
    ctaType: promoData.type,
    ctaWording: getStrippedHtml(promoData.text),
    ctaUrl: trackingUrl,
    ctaLocation: promoData.location,
  });

  trackCustomEvent({ event, context });
};
