'use client';
import { useRef } from 'react';

import { useAtomValue } from 'jotai';
import { usePathname } from 'next/navigation';

import { searchParamsAtom } from '@core/Atoms/SearchParams/SearchParams.atom';
import { userIdAtom } from '@core/Atoms/User/UserId.atom';
import { useIntersectionObserver } from '@core/Hooks/UseIntersectionObserver';
import { generateComponentViewedTracking } from '@tracking/ComponentViewed.tracking';
import { trackCustomEvent } from '@tracking/Utils/TrackCustomEvent';

import styles from './FilteredVehicleCarousel.module.css';

interface FVCClientWrapperProps {
  title: string;
  filterLabel: string;
  viewAllUrl?: string;
  className?: string;
  children: React.ReactNode;
}

export const FilteredVehicleCarouselClientWrapper = ({
  title,
  filterLabel,
  viewAllUrl,
  className = '',
  children,
}: FVCClientWrapperProps) => {
  const wrpr = useRef<HTMLElement>(null);
  const hasTracked = useRef(false);
  const userId = useAtomValue(userIdAtom);
  const searchParams = useAtomValue(searchParamsAtom);
  const pathname = usePathname();
  const pageType = pathname.split('/')[2];

  const { event, context } = generateComponentViewedTracking({
    searchParams,
    userId,
    formState: undefined,
    type: 'filtered-vehicle-carousel',
    wording: title,
    category: filterLabel,
    ctaUrl: viewAllUrl,
    location: pageType || 'homepage',
  });

  useIntersectionObserver(wrpr, entry => {
    if (entry.isIntersecting && hasTracked.current === false) {
      hasTracked.current = true;
      trackCustomEvent({ event, context });
    }
  });

  return (
    <article className={`${styles.wrapper} ${className}`} ref={wrpr}>
      {children}
    </article>
  );
};
