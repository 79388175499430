'use client';

import { memo } from 'react';

import styles from './MultiVid.module.css';

export const PlayButton = memo(() => (
  <div className={styles.playButton}>
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.playIcon}
    >
      <path d="M8 5v14l11-7z" />
    </svg>
  </div>
));

PlayButton.displayName = 'PlayButton';
