/* eslint-disable @typescript-eslint/naming-convention */
'use client';

import { useState } from 'react';

import Link from 'next/link';

import { sanitizePath } from '@native-navigation/Utils/NativeScreenTypeMap';

type Props = React.ComponentProps<typeof Link>;

export const PrefetchLink = (props: Props) => {
  const { href, children, ...rest } = props;
  const { prefetch = false } = props;

  const [shouldPrefetch, setShouldPrefetch] = useState(prefetch);
  const sanitizedHref = sanitizePath(href);

  return (
    <Link
      prefetch={shouldPrefetch}
      href={sanitizedHref ?? '/'}
      onMouseEnter={() => setShouldPrefetch(true)}
      {...rest}
    >
      {children}
    </Link>
  );
};
