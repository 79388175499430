import { LeadFormState } from '@core/Atoms/Pdp/FormState.atom';
import { SearchParamsType } from '@core/Atoms/SearchParams/SearchParams.atom';

import { componentViewedToEvent } from './Schemas/ComponentViewed/ComponentViewedToEvent';
import { marketingToEvent } from './Schemas/Marketing/MarketingToEvent';
import { userContextToEvent } from './Schemas/UserContext/UserContextToEvent';

export interface ComponentView {
  searchParams: SearchParamsType;
  userId: string | null | undefined;
  formState?: LeadFormState;
  type: string;
  wording: string;
  category: string;
  ctaUrl?: string;
  location: string;
}

export function generateComponentViewedTracking({
  searchParams,
  userId,
  formState,
  type,
  wording,
  category,
  ctaUrl,
  location,
}: ComponentView) {
  const marketingDataObj = {
    marketingValues: { ...(searchParams ?? {}) },
  };

  const userData = {
    userId,
    values: {},
  };
  if (formState) {
    userData.values = { ...formState };
  }

  const cvEventObj = {
    compType: type,
    compWording: wording,
    compCategory: category,
    targetUrl: ctaUrl,
    compLocation: location,
  };

  // event actions
  const componentViewedEvent = componentViewedToEvent(cvEventObj);

  // event entities
  const userEvent = userContextToEvent(userData);
  const marketingEvent = marketingToEvent(marketingDataObj);

  const context = [userEvent, marketingEvent];

  return { event: componentViewedEvent, context };
}
