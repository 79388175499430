'use client';

import { FC, useState } from 'react';

import { Tabs } from '@gds/Tabs/Tabs';
import { Typography } from '@gds/Typography/Typography';
import { Scroller } from '@shared-components/Scroller/Scroller';

import { MultiVidContent } from './Entities/MultiVid.entity';
import { VideoCard } from './VideoCard';

import styles from './MultiVid.module.css';

export const MultiVid: FC<MultiVidContent> = props => {
  const { heading, description, categories } = props;
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  if (!categories?.length) return null;

  const tabButtons = categories.map(category => ({
    label: category.label,
    dataTestId: `multi-vid-tab-${category.label.toLowerCase().replace(/\s+/g, '-')}`,
  }));

  const tabContents = categories.map((category, categoryIndex) => (
    <div key={`category-${categoryIndex}`} className={styles.tabContent}>
      <Scroller scrollItemClass={styles.videoItem}>
        <div className={styles.videoItems}>
          {category.items.map((item, itemIndex) => (
            <VideoCard
              key={`video-${categoryIndex}-${itemIndex}`}
              item={item}
              index={itemIndex}
              categoryIndex={categoryIndex}
            />
          ))}
        </div>
      </Scroller>
    </div>
  ));

  return (
    <div className={styles.wrapper} data-test-id="multi-vid-component">
      {heading && (
        <Typography Component="h2" variant="h3" className={styles.heading}>
          {heading}
        </Typography>
      )}

      {description && (
        <Typography variant="body1" className={styles.description}>
          {description}
        </Typography>
      )}

      <Tabs
        activeIndex={activeTabIndex}
        buttons={tabButtons}
        onChange={setActiveTabIndex}
        tabContents={tabContents}
        idPrefix="multi-vid"
        className={styles.tabs}
        dataTestId="multi-vid-tabs"
      />
    </div>
  );
};

MultiVid.displayName = 'MultiVid';
