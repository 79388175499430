'use client';

import { recentlyViewedClickToEvent } from '@tracking/Schemas/RecentlyViewedClick/RecentlyViewedClickToEvent';
import { trackCustomEvent } from '@tracking/Utils/TrackCustomEvent';

import styles from './RecentlyViewed.module.css';

type Props = {
  vehicleId: string;
  children: React.ReactNode;
};

export const RecentlyViewedClickEvent = ({ vehicleId, children }: Props) => {
  const trackClick = () => {
    trackCustomEvent({
      event: recentlyViewedClickToEvent({ listingId: vehicleId }),
      context: [],
    });
  };

  return (
    <span className={styles.trackElement} onClick={trackClick}>
      {children}
    </span>
  );
};
