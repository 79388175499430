'use client';
import { useEffect, useRef, useState } from 'react';

import { Play } from '@gds/Icons/Paths/Play';
import { KenticoImage } from '@shared-components/KenticoImage/KenticoImage';

import { YOUTUBE_PLAYER_API, YOUTUBE_NOCOOKIE_HOST } from './YouTube.constants';

import styles from '../VideoPromo.module.css';

type Props = {
  videoId: string;
  kenticoImage?: string;
  playerId?: string;
};

export const YoutubeVideo = ({ videoId, kenticoImage, playerId = 'ytPlayer' }: Props) => {
  const [player, setPlayer] = useState({});
  const [hasPlayed, setHasPlayed] = useState(false);
  const $script = useRef<HTMLScriptElement | null>(null);
  const $video = useRef<HTMLDivElement>(null);
  const scriptId = `youtube-player-api`;

  const init = (entries: IntersectionObserverEntry[]) => {
    if (!entries?.[0]?.isIntersecting) return;

    const script = document.createElement('script');
    script.id = scriptId;
    script.src = YOUTUBE_PLAYER_API;
    script.async = true;

    if (document.getElementById(scriptId)) {
      document.body.removeChild?.(document.getElementById(scriptId)!);
    }

    document.body.appendChild(script);
    $script.current = script;

    const initPlayer = () => {
      // @ts-expect-error - YT is a global function
      const player = new YT.Player(playerId, {
        height: '100%',
        width: '100%',
        videoId,
        host: YOUTUBE_NOCOOKIE_HOST,
        playerVars: {
          playsinline: 1,
          modestbranding: 1,
          controls: 0,
          showinfo: 0,
          origin: window.location.host,
        },
      });

      setPlayer(player);
    };

    // @ts-expect-error - YouTubePlayerAPIReady is a global function
    if (window.onYouTubePlayerAPIReady) initPlayer();

    // @ts-expect-error - YouTubePlayerAPIReady is a global function
    window.onYouTubePlayerAPIReady = () => {
      initPlayer();
    };
  };

  useEffect(() => {
    const observer = new IntersectionObserver(init, { threshold: 0 });
    observer.observe($video.current!);

    return () => {
      observer?.disconnect();
      if ($script?.current && document.body) document.body.removeChild?.($script?.current);
    };
  }, []);

  const play = () => {
    // @ts-expect-error - playVideo is a method on the player
    player?.playVideo?.();
    setHasPlayed(true);
  };

  return (
    <div className={`${styles.video} video`} ref={$video}>
      {kenticoImage && (
        <>
          <KenticoImage
            alt="Buy online video thumbnail"
            src={kenticoImage}
            className={styles.thumbnail}
            onClick={play}
            data-has-started={hasPlayed}
            quality={90}
            sizes="(max-width: 768px) 20vw, (max-width: 1200px) 33vw"
            fill
          />
          <Play className={styles.playIcon} data-has-started={hasPlayed} />
        </>
      )}
      <div id={playerId} />
    </div>
  );
};

YoutubeVideo.displayName = 'YoutubeVideo';
