/* eslint-disable @typescript-eslint/naming-convention */
'use client';

import { FC, useEffect, useState } from 'react';

import { formStateAtom } from '@core/Atoms/Pdp/FormState.atom';
import { searchParamsAtom } from '@core/Atoms/SearchParams/SearchParams.atom';
import { userIdAtom } from '@core/Atoms/User/UserId.atom';
import { DEFAULT_LOCALE } from '@core/Constants/Locale.constants';

import Button from '@gds/Button/Button';
import { SuccessTickCircle } from '@gds/Icons/Paths/SuccesTickCircle';

import { Input } from '@gds/Input/Input';
import { Typography } from '@gds/Typography/Typography';
import { optInAction } from '@lead-forms/Actions/OptIn.action';
import { OPT_IN_URLS } from '@lead-forms/Constants/OptIn.constants';
import { useOptIn } from '@lead-forms/Hooks/UseOptIn';
import { trackCustomEvent } from '@tracking/Utils/TrackCustomEvent';
import { useAtomValue } from 'jotai';

import { resolveAction } from 'Actions/User/Resolve.action';

import { useInput } from 'Hooks/UseInput';

import { EMAIL_VALIDATION } from 'Utils/Validations';

import { EmailInputFormProps } from './EmailInputForm.entity';

import { generateSubmitButtonTracking } from './EmailInputForm.tracking';

import styles from './EmailInputForm.module.css';

export const EmailInputForm: FC<EmailInputFormProps> = ({ strings, locale, tenant }) => {
  const {
    value: email,
    isValid,
    error,
    handleBlur,
    handleChange,
    setCustomError,
  } = useInput(EMAIL_VALIDATION, strings.emailInvalidError);
  const [isLoading, setIsLoading] = useState(false);

  const user_Id = useAtomValue(userIdAtom);
  const searchParams = useAtomValue(searchParamsAtom);
  const formState = useAtomValue(formStateAtom);

  const { optInData, setOptIn } = useOptIn();

  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    e.preventDefault();
    const url = locale && OPT_IN_URLS[locale];

    if (!isValid || !url) return;

    let userId = null;

    try {
      if (locale === DEFAULT_LOCALE) {
        ({ user_id: userId } = await resolveAction(user_Id!, { email }, optInData));
      }
      const response = await optInAction(
        {
          locale,
          email,
          userId,
          user_Id,
          ...(tenant && { tenant }),
        },
        strings,
      );

      if (response.error) {
        setCustomError(response.error);
      } else {
        const { event, context } = generateSubmitButtonTracking({
          searchParams,
          userId: user_Id,
          formState,
          ctaType: 'button',
          ctaWording: strings.signUpCta,
          ctaUrl: url,
          ctaLocation: 'footer',
        });

        trackCustomEvent({ event, context });
        setOptIn(email);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setCustomError(strings.emailError);
    }
  };
  if (!hasMounted) return null;

  if (optInData.email) {
    return (
      <div className={styles.signUpSuccessMessage}>
        <SuccessTickCircle />
        <Typography variant="body1">{strings.signUpSucess}</Typography>
      </div>
    );
  }
  return (
    <form onSubmit={handleSubmit} className={styles.emailInput} id="email-input-form">
      <Input
        onChange={handleChange}
        value={email}
        onBlur={handleBlur}
        {...(((email && !isValid) || error) && { error })}
        name="email"
        disabled={isLoading}
        placeholder={strings.placeHolder}
        fullWidth
        type="email"
        required
      />
      <Button loading={isLoading}>{strings.signUpCta}</Button>
    </form>
  );
};
