'use client';

import { ChangeEvent, useState } from 'react';

import { getClientLocale } from '@cookies/GetClientLocale';
import { VehicleValuationData } from '@core/Entities/Vehicle/Valuation.entity';
import { getFormattedCurrency } from '@core/Utils/Intl/GetFormattedCurrency';
import Button from '@gds/Button/Button';
import Grid from '@gds/Grid/Grid';
import { Mileage } from '@gds/Icons/Paths/Mileage';
import { RegNr } from '@gds/Icons/Paths/RegNr';
import { Input } from '@gds/Input/Input';
import { Typography } from '@gds/Typography/Typography';
import { valuationAtom } from '@valuation/Atoms/Valuation.atom';
import { useAtom } from 'jotai';
import Link from 'next/link';

import { tradeInAction } from 'Actions/TradeIn/TradeIn.action';

import { tradeInWidgetMessages } from './TradeInWidget.messages';

import styles from './TradeInWidget.module.css';

interface TradeInWidgetProps {
  strings: Record<keyof typeof tradeInWidgetMessages, string>;
}

const TradeInWidget = ({ strings }: TradeInWidgetProps) => {
  const [reg, setReg] = useState<string>('');
  const [mileage, setMileage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const [valuation, setValuation] = useAtom(valuationAtom);
  const locale = getClientLocale();

  const handleEdit = () => {
    setValuation(null);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>, name: 'reg' | 'mileage') => {
    if (name === 'reg') setReg(e.target.value);
    if (name === 'mileage') setMileage(e.target.value);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!reg || !mileage) return false;

    setIsLoading(true);
    const res = await tradeInAction(reg, mileage, locale);

    if (res.error) {
      setError(res.error);
    } else {
      setValuation(res.data as VehicleValuationData);
    }

    setIsLoading(false);
  };

  return (
    <Grid.Container component="form" className={styles.tradeInWrapper} onSubmit={onSubmit}>
      {valuation ? (
        <Grid.Row
          align="middle"
          className={styles.row}
          gutter={{ mobile: 40, tablet: 20, desktop: { m: 24 } }}
        >
          <Grid.Col className={styles.valuationCol} width={{ mobile: 12, tablet: 4, desktop: 4 }}>
            <Typography Component="label" variant="caption1">
              {strings.carTitle}
            </Typography>
            <Typography
              Component="p"
              className={styles.vehicleName}
              variant="h6"
            >{`${valuation.vehicle.make} ${valuation.vehicle.model}`}</Typography>
            <Button
              className={`typography-button-2 ${styles.editBtn}`}
              onClick={handleEdit}
              size="small"
              variant="link"
            >
              {strings.editButton}
            </Button>
            <hr className={styles.divider} />
          </Grid.Col>

          <Grid.Col className={styles.valuationCol} width={{ mobile: 12, tablet: 4, desktop: 4 }}>
            <Typography Component="label" variant="caption1">
              {strings.valuationTitle}
            </Typography>
            <Typography Component="p" variant="h6">{`${getFormattedCurrency(
              valuation.valuation.low,
              locale,
            )} - ${getFormattedCurrency(valuation.valuation.high, locale)}`}</Typography>
          </Grid.Col>

          <Grid.Col className={styles.valuationCol} width={{ mobile: 12, tablet: 4, desktop: 4 }}>
            <Link href={`/${locale}/autos`} passHref legacyBehavior>
              <Button Component="a" dataTestId="findCarBtn">
                {strings.findCarButton}
              </Button>
            </Link>
          </Grid.Col>
        </Grid.Row>
      ) : (
        <Grid.Row align="middle" gutter={{ mobile: 40, tablet: 20, desktop: { m: 24 } }}>
          <Grid.Col className={styles.col} width={{ mobile: 12, tablet: 4, desktop: 4 }}>
            <Input
              className={styles.input}
              dataTestId="tradein-reg"
              error={error ? strings.valuationError : undefined}
              onChange={e => handleChange(e, 'reg')}
              name="registration"
              placeholder={strings.regPlaceholder}
              value={reg}
              required
              rightIcon={<RegNr />}
            />
            <hr className={styles.divider} />
          </Grid.Col>

          <Grid.Col className={styles.col} width={{ mobile: 12, tablet: 4, desktop: 4 }}>
            <Input
              className={styles.input}
              dataTestId="tradein-mileage"
              onChange={e => handleChange(e, 'mileage')}
              placeholder={strings.milagePlaceholder}
              value={mileage}
              max="999999"
              min="0"
              name="mileage"
              pattern="\d*"
              required
              type="number"
              rightIcon={<Mileage />}
            />
          </Grid.Col>

          <Grid.Col className={styles.col} width={{ mobile: 12, tablet: 4, desktop: 4 }}>
            <Button dataTestId="tradein-search" type="submit" loading={isLoading}>
              {strings.searchButton}
            </Button>
          </Grid.Col>
        </Grid.Row>
      )}
    </Grid.Container>
  );
};

export default TradeInWidget;
