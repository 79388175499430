import { ComponentViewedEvent } from './ComponentViewedEvent';
import { componentViewedSchema } from './ComponentViewedSchema';

export const componentViewedToEvent = ({
  compType,
  compWording,
  compCategory,
  targetUrl,
  compLocation,
}: {
  compType: string;
  compWording: string;
  compCategory: string;
  targetUrl?: string;
  compLocation: string;
}) => {
  const event: ComponentViewedEvent = {
    schema: componentViewedSchema.ref,
    data: {
      comp_type: compType,
      comp_wording: compWording,
      comp_category: compCategory,
      target_url: targetUrl || '',
      comp_location: compLocation,
    },
  };

  return event;
};
