import { RecentlyViewedClickEvent } from './RecentlyViewedClickEvent';
import { recentlyViewedClickSchema } from './RecentlyViewedClickSchema';

type Props = {
  listingId: string | null;
};

export const recentlyViewedClickToEvent = ({ listingId }: Props): RecentlyViewedClickEvent => {
  const event: RecentlyViewedClickEvent = {
    schema: recentlyViewedClickSchema.ref,
    data: {
      listing_id: listingId,
    },
  };

  return event;
};
