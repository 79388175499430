'use client';

import { useState } from 'react';

import { useRouter } from 'next/navigation';

import { Locale } from '@core/Entities/Locale/Locale.entity';
import Button from '@gds/Button/Button';
import { Euro } from '@gds/Icons';
import { Input } from '@gds/Input/Input';

import { addCustomFilterParams } from './Utils/EvFilterParams.utils';

import styles from './EvSearchBanner.module.css';

type SearchFormProps = {
  strings: Record<string, string>;
  from: number;
  setFrom: (value: number) => void;
  to: number;
  setTo: (value: number) => void;
  range: number;
  setRange: (value: number) => void;
  isMonthly: boolean;
  locale: Locale;
  customFilterParams?: Record<string, string>;
};

export const SearchForm = ({
  strings,
  from,
  setFrom,
  to,
  setTo,
  range,
  setRange,
  isMonthly,
  locale,
  customFilterParams,
}: SearchFormProps) => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    const searchParams = new URLSearchParams();

    if (range !== 0) {
      searchParams.append('ev-wltp-range__gte', range.toString());
    }
    searchParams.append(isMonthly ? 'monthly-price__gte' : 'price__gte', from.toString());
    searchParams.append(isMonthly ? 'monthly-price__lte' : 'price__lte', to.toString());

    // Add custom filter parameters using utility function
    addCustomFilterParams(searchParams, customFilterParams);

    router.push(`/${locale}/autos/fueltype/electrique?${searchParams.toString()}`);
  };

  return (
    <form className={styles.searchBannerForm} onSubmit={handleSubmit}>
      <Input
        type="number"
        min={0}
        className={styles.input}
        label={strings.from}
        value={from}
        dataTestId="ev-search-banner-from"
        onChange={e => setFrom(Number(e.target.value))}
        rightIcon={<Euro />}
      />
      <Input
        type="number"
        min={0}
        className={styles.input}
        label={strings.to}
        value={to}
        dataTestId="ev-search-banner-to"
        onChange={e => setTo(Number(e.target.value))}
        rightIcon={<Euro />}
      />
      <Input
        type="number"
        min={0}
        className={styles.input}
        label={strings.range}
        value={range}
        dataTestId="ev-search-banner-range"
        onChange={e => setRange(Number(e.target.value))}
        rightAddons={['km']}
      />
      <Button type="submit" loading={isLoading}>
        {strings.findMyCar}
      </Button>
    </form>
  );
};
