/* eslint-disable @typescript-eslint/naming-convention */
'use server';

import { fetchJson } from '@core/Utils/Fetch/FetchJson';
import { OptInData } from '@lead-forms/Entities/OptIn.entities';

import { newOptIns } from './UserService.util';

export interface UserFormData {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  postcode?: string;
  marketing_optin_email?: boolean;
  marketing_optin_phone?: boolean;
}

export interface HcUser {
  user_id: string;
}

export async function resolveAction(user_id: string, formData: UserFormData, optInData: OptInData) {
  let user = { user_id };
  const { email, firstName, lastName, phone } = formData;

  const payload = {
    ...newOptIns(formData, optInData),
    email,
    first_name: firstName,
    last_name: lastName,
    phone,
    user_id,
  };

  try {
    const hcUser: HcUser = await fetchJson(`/api/user/resolve/`, {
      method: 'POST',
      body: JSON.stringify(payload),
    });

    user = hcUser;
  } catch (error) {
    // ignore user resolve error and move on
  }

  return user;
}
